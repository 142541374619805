import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export default i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            lookupQuerystring: 'language',
            lookupCookie: 'language',
            lookupLocalStorage: 'language',
            lookupSessionStorage: 'language',
        },
        keySeparator: '|',
        nsSeparator: '@',
        fallbackLng: 'bg',
        saveMissingTo: 'fallback',
        supportedLngs: ['bg', 'en'],
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    })
;
