import {ApiService} from './api.service';
import {UploadFileModel} from './../../models/upload-file.model';


export type ApiUploadsServiceTypes = ('image'|'file'|'homework'|'certificate'|'avatar');

export class ApiUploadsService {

    /**
     * Upload an image file
     *
     * @param filename Original filename of uploaded file
     * @param file File blob
     * @param chunks On how many chunks the file is separated
     * @param chunk Current sent chunk
     * @param onProgress
     */
    static async image(filename: string, file: Blob, chunks?: number, chunk?: number, onProgress?: CallableFunction): Promise<{data: UploadFileModel}> {
        return ApiUploadsService.makeRequest('image', filename, file, chunks, chunk, onProgress);
    }

    /**
     * Upload a general-purpose file
     *
     * @param filename Original filename of uploaded file
     * @param file File blob
     * @param chunks On how many chunks the file is separated
     * @param chunk Current sent chunk
     * @param onProgress
     */
    static async file(filename: string, file: Blob, chunks?: number, chunk?: number, onProgress?: CallableFunction): Promise<{data: UploadFileModel}> {
        return ApiUploadsService.makeRequest('file', filename, file, chunks, chunk, onProgress);
    }

    /**
     * Upload a user-specific homework
     *
     * @param filename Original filename of uploaded file
     * @param file File blob
     * @param chunks On how many chunks the file is separated
     * @param chunk Current sent chunk
     * @param onProgress
     */
    static async homework(filename: string, file: Blob, chunks?: number, chunk?: number, onProgress?: CallableFunction): Promise<{data: UploadFileModel}> {
        return ApiUploadsService.makeRequest('homework', filename, file, chunks, chunk, onProgress);
    }

    /**
     * Upload a user-specific certificate
     *
     * @param filename Original filename of uploaded file
     * @param file File blob
     * @param chunks On how many chunks the file is separated
     * @param chunk Current sent chunk
     * @param onProgress
     */
    static async certificate(filename: string, file: Blob, chunks?: number, chunk?: number, onProgress?: CallableFunction): Promise<{data: UploadFileModel}> {
        return ApiUploadsService.makeRequest('certificate', filename, file, chunks, chunk, onProgress);
    }

    /**
     * Upload a user-specific avatar
     *
     * @param filename Original filename of uploaded file
     * @param file File blob
     * @param chunks On how many chunks the file is separated
     * @param chunk Current sent chunk
     * @param onProgress
     */
    static async avatar(filename: string, file: Blob, chunks?: number, chunk?: number, onProgress?: CallableFunction): Promise<{data: UploadFileModel}> {
        return ApiUploadsService.makeRequest('avatar', filename, file, chunks, chunk, onProgress);
    }

    /**
     * Make request
     */
    static makeRequest(
        type: ApiUploadsServiceTypes,
        filename: string,
        file: Blob,
        chunks?: number,
        chunk?: number,
        onProgress?: CallableFunction
    ): Promise<{data: UploadFileModel}> {
        let data = new FormData();
        data.append('file', file, filename);

        if (Number(chunks) >= 1 && Number(chunk) >= 0 ) {
            data.append('chunks', '' + Number(chunks));
            data.append('chunk', '' + Number(chunk));
        }

        return ApiService.post('uploads/' + type, data, {
            headers: {
                'Content-Type': 'application/octet-stream'
            },
            onUploadProgress: (event: any) => {
                onProgress && onProgress(event);
            }
        });
    }
}
