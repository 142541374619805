export function Observer<T>() {
    class Observer {
        protected static listeners: {[name in string]: Array<CallableFunction>} = {};

        /**
         * Subscribe for an event
         */
        static on(event: T, callback: CallableFunction = () => {}) {
            let name: string = event + '';
            if (!(name in this.listeners)) {
                this.listeners[name] = [];
            }
            this.listeners[name].push(callback);
        }

        /**
         * Unsubscribe from an event
         */
        static off(event: T, callback?: CallableFunction) {
            let name: string = event + '';

            if (!callback || !(callback instanceof Function)) {
                this.listeners[name] = [];
            } else {
                this.listeners[name] = this.listeners[name].filter(item => item !== callback);
            }
        }

        protected static fire(event: T, params?: any) {
            let name: string = event + '';
            (this.listeners[name] || []).forEach(item => {
                item(params);
            });
        }
    }

    return Observer;
}


