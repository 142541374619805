import React from 'react';
import {UserModel} from './../../models/user/user.model';
import classes from './avatar.module.scss';

const md5 = require('crypto-js/md5');

interface Props {
    className?: any;
    user: UserModel;
    size?: number;
};

export class Avatar extends React.Component<Props> {
    innerIsMounted: boolean = false;

    static defaultProps: Props = {
        className: '',
        user: {} as UserModel,
        size: 65,
    };
    state = {
        loading: true,
        name: '',
        initials: '',
        url: '',
        user: {} as UserModel,
    }

    componentDidMount() {
        this.innerIsMounted = true;
    }

    componentWillUnmount() {
        this.innerIsMounted = false;
    }

    componentDidUpdate() {
        if (this.isUserUpdated(this.props.user)) {
            this.setState({user: this.props.user});

            const name = [
                this.props.user.name || '',
                this.props.user.surname || '',
                this.props.user.lastname || ''
            ].join(' ').trim();

            let url = this.props.user.image
                || 'https://www.gravatar.com/avatar/' + md5(this.props.user.email) + '?s=' + this.props.size + '&d=404';

            fetch(url).then(response => {
                if (this.innerIsMounted && response.status >= 200 && response.status <= 299) {
                    return this.setState({name, url});
                }
                throw new Error("404");
            }).catch(() => {
                const names = name.toUpperCase().split(' ');
                this.innerIsMounted && this.setState({
                    name,
                    initials: (names[0] || [''])[0] + (names.length > 1 ? (names[names.length - 1] || [''])[0] : ''),
                });
            });
        }
    }

    protected isUserUpdated(user?: UserModel): boolean {
        return !!((user && !this.state.user)
            || (!user && this.state.user)
            || (user?.email !== this.state.user?.email)
            || (user?.image !== this.state.user?.image)
            || (user?.name !== this.state.user?.name)
            || (user?.surname !== this.state.user?.surname)
            || (user?.lastname !== this.state.user?.lastname)
        );
    }

    render() {


        return (
            <div className={classes.wrap + ' ' + this.props.className} style={{height: this.props.size + 'px', width: this.props.size + 'px'}} title={this.state.name}>
                {this.state.initials ? <span style={{fontSize: Math.round((this.props.size || 1) / 3) + 'px'}}>{this.state.initials}</span> : null}
                {this.state.url ? <div style={{backgroundImage: 'url(' + this.state.url + ')'}}></div> : null }
            </div>
        );
    }
}
