import {ApiService} from './api.service';
import {UserModel} from './../../models/user/user.model';

interface InputUser {
    image?: string;
    name?: string;
    surname?: string;
    lastname?: string;
    phone?: string;
    email?: string;
    password?: string;
}

export class ApiProfileService extends ApiService {
    /**
     * Get current profile data
     */
    static async profile(): Promise<{data: UserModel}> {
        return this.get('profile');
    }

    /**
     * Store new settings for profile
     *
     * @param user
     */
    static async store(user: InputUser): Promise<{data: UserModel}> {
        return await this.post('profile', user);
    }
}
