import axios, {AxiosResponse} from 'axios';
import i18next from 'i18next';

import {Config} from './../config.service';
import {Observer} from './../../contracts/observer';

type ApiServiceEvents = ('error'|'unauthorized');

let TOKEN: string = '';

export class ApiService extends Observer<ApiServiceEvents>() {
    static readonly API_URL = Config.API || '';

    static set AUTHORIZATION(auth: string) {
        if (TOKEN !== auth) {
            TOKEN = auth;
        }
    }

    static get AUTHORIZATION(): string {
        return TOKEN;
    }

    /**
     * Send a GET request
     */
    static async get(endpoint: string, options: any = {}): Promise<AxiosResponse> {
        return await this.request('get', endpoint || '', {}, options || {});
    }

    /**
     * Send a POST request
     */
    static async post(endpoint: string, data: any = {}, options: any = {}): Promise<AxiosResponse> {
        return await this.request('post', endpoint || '', data || {}, options || {});
    }

    /**
     * Send a PUT request
     */
    static async put(endpoint: string, data: any = {}, options: any = {}): Promise<AxiosResponse> {
        return await this.request('put', endpoint || '', data || {}, options || {});
    }

    /**
     * Send a PATCH request
     */
    static async patch(endpoint: string, data: any = {}, options: any = {}): Promise<AxiosResponse> {
        return await this.request('patch', endpoint || '', data || {}, options || {});
    }

    /**
     * Send a DELETE request
     * *NOTE* DELETE doesn't have a body!
     */
    static async delete(endpoint: string, options: any = {}): Promise<AxiosResponse> {
        return await this.request('delete', endpoint || '', {}, options || {});
    }

    /**
     * Get Full URL to API request
     */
    static getFullUrl(endpoint: string): string {
        return ApiService.API_URL.trim() + (endpoint || '').trim();
    }

    protected static async request(method: string, endpoint: string, data?: any, options?: any): Promise<AxiosResponse> {
        endpoint = endpoint || '';
        data = data || {};
        options = options || {};
        const auth = this.AUTHORIZATION || '';

        try {
            options = ApiService.mergeDeep(i18next.language ? {headers: {'Accept-Language': i18next.language}} : {}, options);
            options = ApiService.mergeDeep(auth && auth.length ? {headers: {'Authorization': 'Bearer ' + auth}} : {}, options);

            const result = await axios({
                method,
                url: this.getFullUrl(endpoint),
                data,
                ...options,
            });

            return result.data;
        } catch (e) {

            if (e && 'response' in e && 'status' in e.response && e.response.status === 401) {
                this.fire('unauthorized', e);
            } else {
                this.fire('error', e);
            }

            throw e;
        }
    }

    /**
     * Simple is object check.
     * @param item
     */
    protected static isObject(item: any): boolean {
        return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
    }


    /**
     * Deep merge two objects.
     * @param target
     * @param source
     */
    protected static mergeDeep(target: any, source: any) {
        if (ApiService.isObject(target) && ApiService.isObject(source)) {
            Object.keys(source).forEach(key => {
                if (ApiService.isObject(source[key])) {
                    if (!target[key] || !ApiService.isObject(target[key])) {
                        target[key] = source[key];
                    }
                    ApiService.mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            });
        }
        return target;
    }
}
