import React from 'react';
import classes from './layout-main.module.scss';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {auth} from './../../../services/auth.service';
import {LayoutTopNav} from './../top-nav/layout-top-nav';
import {LayoutSidebar} from './../sidebar/sidebar';


interface Message {
    message: string,
    type: ('primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark')|string
};
interface Props {
    className?: any;
    content: JSX.Element,
    message?: Message,
};

export class LayoutMain extends React.Component<Props> {
    store = {
        role: auth().getRole()
    };

    state = {
        showInstructions: false
    };

    componentDidMount() {

    }

    onMenuSelected(item: any): void {
        if (item.id && item.id === 'instructions') {
            this.setState({
                showInstructions: true
            });
        }
    }

    render() {
        return (
            <div className={classes.layout}>
                <div className="container-fluid">
                    <div className="row">
                        <div className={classes.sidebar + ' col-lg-2'}>
                            <LayoutSidebar onMenuSelected={this.onMenuSelected.bind(this)} />
                        </div>

                        <div className="col-lg-10">
                            <LayoutTopNav />

                            <div className={classes.content}>
                                {!this.props.message || !(this.props.message.message || '').length ? null : (
                                    <Alert variant={this.props.message.type}>{this.props.message.message}</Alert>
                                )}

                                {this.props.content}
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.showInstructions} size="lg">
                    <Modal.Header>
                        <Modal.Title as="div" className={classes.popup_title}>Инструкции за работа с платформата:</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <video muted={true} loop={true} controls={true} autoPlay={true} className={classes.video}
                            poster="https://online.advanceacademy.bg/assets/img/academy.jpg"
                        >
                            <source src="https://online.advanceacademy.bg/assets/img/instructions.m4v" />
                        </video>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.setState({showInstructions: false})}>Затвори</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
}
