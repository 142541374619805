import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';

import {FormService} from './../../services/form.service';
import Upload, {UploadFileQueue} from './../../components/upload/upload';
import {Avatar} from './../../components/avatar/avatar';
import {LayoutMain} from '../../components/layouts/main/layout-main';
import {auth} from '../../services/auth.service';
import {InputError} from './../../components/input-error/input-error';
import { ApiProfileService } from '../../services/api/api-profile.service';

import classes from './profile.module.scss';

class Profile extends React.Component<WithTranslation> {
    private user = auth().user;
    state = {
        loading: false,
        submitting: false,
        role: auth().getRole(),
        avatar: null,
        avatarUrl: this.user.image || '',
        user: this.user,
        name: this.user.name,
        surname: this.user.surname,
        lastname: this.user.lastname,
        email: this.user.email,
        phone: this.user.phone,
        password: '',
        repeatpassword: '',

        // validations
        message: '',
        messageType: '',
        errors: {
            list: [],
            avatar: '',
            user: '',
            name: '',
            surname: '',
            lastname: '',
            email: '',
            phone: '',
            password: '',
            repeatpassword: '',
        }
    };

    componentDidMount() {
        this.setState({needToRedirect: auth().isGuest()});
    }

    isEditAllowed():boolean {
        return this.state.role !== 'student'
    }

    onFileFinished(files: UploadFileQueue[]): void {
        const {t} = this.props;

        if (!files || !files.length || files[0].error) {
            this.setState({
                errors: {...this.state.errors, ...{avatar: t('Невалидна снимка, проблем с качването ѝ, или е прекалено голяма.')}},
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                avatar: files[0].response.data.path,
                avatarUrl: files[0].response.data.url
            });
        }
    }

    onSubmit(e: any): void {
        const {t} = this.props;

        e.preventDefault();

        if (this.state.submitting || this.state.loading) {
            return;
        }

        FormService.resetErrors(this.setState.bind(this), this.state);

        if (!FormService.validate(this.setState.bind(this), this.state, {
            ...(this.state.password.length ? {
                password: 'min:8',
                repeatpassword: 'min:8|same:' + this.state.password,
            } : {}),
            ...(this.state.role !== 'student' ? {
                name: 'required|min:5',
                surname: '',
                lastname: 'required|min:5',
                email: 'required|email|min:5',
                phone: '',
            } : {})
        })) {
            return;
        }

        this.setState({submitting: true});

        ApiProfileService.store({
            image: this.state.avatarUrl ? this.state.avatarUrl : this.user.image,
            name: (this.state.name || '').trim(),
            surname: (this.state.surname || '').trim(),
            lastname: (this.state.lastname || '').trim(),
            phone: (this.state.phone || '').trim(),
            email: (this.state.email || '').trim(),
            password: (this.state.password || '').trim()
        }).then(data => {
            auth().forceUpdateProfile(data.data);

            this.setState({
                message: t('Успешно променен профил!'),
                messageType: 'success',
                password: '',
                repeatpassword: '',
            });
        }).catch(error => {
            FormService.handleServerErrors(error, this.setState.bind(this), this.state);
        }).finally(() => {
            this.setState({submitting: false});
        });

        return;
    }

    render() {
        const {t} = this.props;

        return (
            <LayoutMain
                message={{type: this.state.messageType, message: this.state.message}}
                content={
                <div className="row">
                    <div className="col-md-12 col-12 text-center">
                        <h2 className={classes.subtitleForm}>{t('Редактиране на профил')}</h2>
                    </div>
                    <div className="col-12">
                        <form action="/" method="POST" className={classes.profileForm} onSubmit={this.onSubmit.bind(this)}>
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <label htmlFor="name">{t('Име:')} *</label>

                                    {!this.isEditAllowed() ? <div className={classes.height}>{this.state.name || '-'}</div>:  <>
                                        <input type="text"
                                               name="name"
                                               id="name"
                                               className={'form-control ' + (this.state.errors.name ? 'is-invalid' : '')}
                                               value={this.state.name}
                                               onChange={(e:any)=>this.setState({name:e.target.value})}/>
                                        <InputError message={this.state.errors.name}/>
                                    </>}
                                </div>
                                <div className="col-md-4 col-12">
                                    <label htmlFor="surname">{t('Презиме:')}</label>
                                    {!this.isEditAllowed() ? <div className={classes.height}>{this.state.surname || '-'}</div>:  <>
                                        <input type="text"
                                               name="surname"
                                               id="surname"
                                               className={'form-control ' + (this.state.errors.surname ? 'is-invalid' : '')}
                                               value={this.state.surname}
                                               onChange={(e:any)=>this.setState({surname:e.target.value})}/>
                                        <InputError message={this.state.errors.surname}/>
                                    </>}
                                </div>
                                <div className="col-md-4 col-12">
                                    <label htmlFor="lastname">{t('Фамилия:')} *</label>
                                    {!this.isEditAllowed() ? <div className={classes.height}>{this.state.lastname || '-'}</div>:  <>
                                        <input type="text"
                                               name="lastname"
                                               id="lastname"
                                               className={'form-control ' + (this.state.errors.lastname ? 'is-invalid' : '')}
                                               value={this.state.lastname}
                                               onChange={(e:any)=>this.setState({lastname:e.target.value})}/>
                                        <InputError message={this.state.errors.lastname}/>
                                    </>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <label htmlFor="email">{t('E-mail:')} *</label>
                                    {!this.isEditAllowed() ? <div className={classes.height}>{this.state.email || '-'}</div> : <>
                                        <input type="text"
                                               name="email"
                                               id="email"
                                               className={'form-control ' + (this.state.errors.email ? 'is-invalid' : '')}
                                               value={this.state.email}
                                               onChange={(e:any)=>this.setState({email:e.target.value})}/>
                                        <InputError message={this.state.errors.email}/>
                                    </>}
                                </div>
                                <div className="col-md-4 col-12">
                                    <label htmlFor="phone">{t('Телефон:')}:</label>
                                    {!this.isEditAllowed() ? <div className={classes.height}>{this.state.phone || '-'}</div> : <>
                                        <input type="text"
                                               name="phone"
                                               id="phone"
                                               className={'form-control ' + (this.state.errors.phone ? 'is-invalid' : '')}
                                               value={this.state.phone}
                                               onChange={(e:any)=>this.setState({phone:e.target.value})}/>
                                        <InputError message={this.state.errors.phone}/>
                                    </>}
                                </div>
                                <div className="col-md-4 col-12">
                                    <label htmlFor="avatar">{t('Аватар:')}</label>

                                    <Avatar user={{...this.user, ...{image: this.state.avatarUrl}}} size={40} /> &nbsp;
                                    <Upload type="avatar"
                                            disabled={this.state.loading || this.state.submitting}
                                            onFilesFinished={this.onFileFinished.bind(this)}
                                            onFileProgress={() => this.setState({errors: {...this.state.errors, ...{avatar: ''}}, loading: true})}
                                    />
                                    <InputError message={this.state.errors.avatar}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <label htmlFor="password">{t('Нова парола:')} *</label>

                                    <input type="password"
                                            name="password"
                                            id="password"
                                            className={'form-control ' + (this.state.errors.password ? 'is-invalid' : '')}
                                            value={this.state.password}
                                            onChange={(e:any)=>this.setState({password:e.target.value})}/>
                                    <InputError message={this.state.errors.password}/>
                                </div>
                                <div className="col-md-6 col-12">
                                    <label htmlFor="repassword">{t('Повторете паролата:')} *</label>

                                    <input type="password"
                                            name="repeatpassword"
                                            id="repeatpassword"
                                            className={'form-control ' + (this.state.errors.repeatpassword ? 'is-invalid' : '')}
                                            value={this.state.repeatpassword}
                                            onChange={(e:any)=>this.setState({repeatpassword:e.target.value})}/>
                                    <InputError message={this.state.errors.repeatpassword}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text-center">
                                        <Button className={classes.submit_button} variant="success" type="submit" disabled={this.state.loading || this.state.submitting}>{t('Редактиране')}</Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }/>
        );
    }
}

export default withTranslation()(Profile);

