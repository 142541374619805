import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {LayoutMain} from './../../components/layouts/main/layout-main';
import {auth} from './../../services/auth.service';
import {Course} from './../../components/course/course';
import classes from './home.module.scss';

class Home extends React.Component<WithTranslation> {
    state = {
        role: auth().getRole(),
        user: auth().user
    };

    componentDidMount() {
        this.setState({needToRedirect: auth().isGuest()});
    }

    render() {
        return (
            <LayoutMain className={classes.layout} content={
                <div className="row">
                    <div className="col mt-3">
                        <p>Основна информация</p>
                        <Course></Course>
                    </div>
                </div>
            }/>
        );
    }
}

export default withTranslation()(Home);
