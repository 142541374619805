import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {ApiAuthService} from './../../../services/api/api-auth.service';
import {FormService} from './../../../services/form.service';
import Layout from './../../../components/layouts/guest/layout-guest';
import ButtonSubmit from './../../../components/auth/button-submit/button-submit';
import {InputError} from './../../../components/input-error/input-error';
import classes from './reset.module.scss';


class Reset extends React.Component<WithTranslation> {
    state = {
        password: '',
        repeatPassword: '',
        loading: false,
        email: '',
        token: '',
        message: '',
        messageType: '',
        errors: {
            list: [],
            password: '',
            repeatPassword: '',
        }
    };

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);

        this.setState({
            email: params.get('email'),
            token: params.get('token'),
        });
    }

    onSubmit(e: any): void {
        const {t} = this.props;
        e.preventDefault();

        if (this.state.loading) {
            return;
        }

        FormService.resetErrors(this.setState.bind(this), this.state);
        this.setState({loading: true});

        if (!FormService.validate(this.setState.bind(this), this.state, {
            password: 'required|min:8',
            repeatPassword: 'required|min:8|same:' + this.state.password,
        })) {
            return;
        }

        ApiAuthService.reset(this.state.email, this.state.password, this.state.token).then(user => {
            this.setState({
                message: t('Успешно сменена парола!'),
                messageType: 'success',
                password: '',
                repeatPassword: '',
            });
        }).catch(error => {
            FormService.handleServerErrors(error, this.setState.bind(this), this.state);
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    render() {
        const {t} = this.props;

        return (
            <Layout
                link={{to: '/auth/login', label: t('Вход в системата')}}
                message={{type: this.state.messageType, message: this.state.message}}
                errorsList={this.state.errors.list}
                onSubmit={this.onSubmit.bind(this)}
                form={this.state.messageType === 'success' ? null : <>
                    <div className="form-group">
                        <label htmlFor="password" className={classes.label}>{t('Нова парола')}</label>
                        <input type="password"
                                className={classes.input + ' form-control ' + (this.state.errors.password ? ' is-invalid' : '')}
                                name="password"
                                placeholder={t('Въведете нова парола')}
                                disabled={this.state.loading}
                                value={this.state.password}
                                onChange={(e: any) => this.setState({password: e.target.value})} />
                        <InputError message={this.state.errors.password}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password" className={classes.label}>{t('Повторете парола')}</label>
                        <input type="password"
                                className={classes.input + ' form-control ' + (this.state.errors.repeatPassword ? ' is-invalid ' : '')}
                                name="repeatPassword"
                                placeholder={t('Повторете новата парола')}
                                disabled={this.state.loading}
                                value={this.state.repeatPassword}
                                onChange={(e: any) => this.setState({repeatPassword: e.target.value})} />
                        <InputError message={this.state.errors.repeatPassword} />
                    </div>

                    <div className="form-group">
                        <ButtonSubmit loading={this.state.loading} label={<><i className="fa fa-pencil-square-o"></i> {t('Промяна')}</>}></ButtonSubmit>
                    </div>
                </>}
            />
        );
    }
}

export default withTranslation('auth')(Reset);
