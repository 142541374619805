import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {Link} from 'react-router-dom';

import {auth} from './../../../services/auth.service';
import {Avatar} from './../../avatar/avatar';
import classes from './layout-top-nav.module.scss';

export class LayoutTopNav extends React.Component {
    protected innerIsMounted: boolean = false;

    state = {
        user: auth().user,
        userType: this.getUserType()
    }


    componentWillUnmount() {
        this.innerIsMounted = false;
    }

    componentDidMount() {
        this.innerIsMounted = true;
        auth().onRoleChanged(() => {
            if (this.innerIsMounted) {
                this.setState({
                    user: auth().user,
                    userType: this.getUserType()
                });
            }
        });
    }

    getUserType(): string {
        return (auth().isAdmin() ? 'Администратор'
            : auth().isSuperAdmin() ? 'Супер админ'
            : auth().isStudent() ? 'Курсист'
            : auth().isTeacher() ? 'Преподавател'
            : '').toLowerCase()
    }

    render() {
        return (
            <div className={classes.navigation}>
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <p className={classes.welcome}>
                            Добре дошли, {this.state.user?.name} {this.state.user?.lastname}!
                        </p>
                    </div>
                    <div className="col-xs-12 col-sm-6 text-right">
                        <Dropdown>
                            <Dropdown.Toggle variant="" className={classes.btn}>
                                <Avatar className={classes.avatar} user={this.state.user} size={40} />
                                <span className={classes.userName}>{this.state.user.name} {this.state.user.lastname}</span>
                                <span className={classes.userType}>{this.state.userType}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/profile" className={classes.dropdown}>
                                    <div className={classes.items}>
                                        <i className="fa fa-fw fa-cog"></i>
                                        <span>Профил</span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/messages" className={classes.dropdown}>
                                    <div className={classes.items}>
                                        <i className="fa fa-fw fa-envelope"></i>
                                        <span>Съобщения</span>
                                    </div>
                                </Dropdown.Item>
                                <hr/>
                                <Dropdown.Item onClick={() => auth().logout().finally()} className={classes.dropdown}>
                                    <div className={classes.items}>
                                        <i className="fa fa-fw fa-sign-out"></i>
                                        <span>Изход</span>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        )
    }

}
