import {ApiService} from './api.service';
import {DefaultMessageModel} from './../../models/default/default-message.model';
import {UserAuthModel} from './../../models/user/user-auth.model';

export class ApiAuthService extends ApiService {
    /**
     * Attempt to sign-in a user
     */
    static async login(email: string, password: string): Promise<{data: UserAuthModel}> {
        return this.post('auth/login', {
            email: (email || '').trim(),
            password,
        });
    }

    /**
     * Performs a logout
     */
    static async logout(): Promise<{data: DefaultMessageModel}> {
        return await this.post('auth/logout');
    }

    /**
     * Reset user password
     */
    static async reset(email: string, password: string, token: string): Promise<{data: DefaultMessageModel}> {
        return this.post('auth/reset', {
            email,
            password,
            token,
        });
    }

    /**
     * Send request for changing user password
     */
    static async forgot(email: string): Promise<{data: DefaultMessageModel}> {
        return this.post('/auth/forgot', {email: (email || '').trim()});
    }
}
