import {RouteModel as Route, RouteRoles} from './../models/route.model';

export function getFlatRoutes(routes: Route[], root?: Route): Route[] {
    let result: Route[] = [];
    let mergeRoles: (a: Route|undefined, b: Route|undefined) => RouteRoles[] = (a, b) =>
        [...((a || {}).roles || []), ...((b || {}).roles || [])];
    let getPath: (a: Route|undefined, b: Route) => string = (a, b) =>
        [((a||{}).path || '').replace(/\/+$/, ""), b.path.replace(/^\/+/, "").replace(/\/+$/, "")].join('/');

    routes.forEach((route: Route) => {
        if (route.component || (root && root.component)) {
            result.push({
                path: getPath(root, route),
                component: route.component || (root || {}).component,
                roles: mergeRoles(root, route),
            });
        }

        if (route.children && route.children.length) {
            route.children.forEach((child) => {
                let rootItem = {...route, ...root};
                rootItem.path = getPath(root, route);
                rootItem.roles = mergeRoles(root, route);
                result.push(...getFlatRoutes([child], rootItem));
            });
        }
    });

    return result;
}
