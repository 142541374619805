import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {ApiAuthService} from './../../../services/api/api-auth.service';
import {auth} from './../../../services/auth.service';
import {FormService} from './../../../services/form.service';
import Layout from './../../../components/layouts/guest/layout-guest';
import ButtonSubmit from './../../../components/auth/button-submit/button-submit';
import {InputError} from './../../../components/input-error/input-error';
import classes from './login.module.scss';

class Login extends React.Component<WithTranslation> {
    state = {
        email: '',
        password: '',
        loading: false,
        message: '',
        messageType: '',
        errors: {
            list: [],
            email: '',
            password: '',
        }
    };

    onSubmit(e: any): void {
        e.preventDefault();

        if (this.state.loading) {
            return;
        }

        FormService.resetErrors(this.setState.bind(this), this.state);
        if (!FormService.validate(this.setState.bind(this), this.state, {
            email: 'required|email|min:5',
            password: 'required|min:8'
        })) {
            return;
        }

        this.setState({loading: true});

        ApiAuthService.login(this.state.email, this.state.password).then(user => {
            auth().setToken(user.data.token).finally();
        }).catch(error => {
            FormService.handleServerErrors(error, this.setState.bind(this), this.state);
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Layout
                className={classes.layout}
                link={{to: '/auth/forgot', label: t('Забравена парола?')}}
                onSubmit={this.onSubmit.bind(this)}
                message={{type: this.state.messageType, message: this.state.message}}
                form={<>
                    <div className="form-group">
                        <input type="text"
                                className={'form-control ' + (this.state.errors.email ? 'is-invalid' : '')}
                                name="email"
                                placeholder={ t('Email:') }
                                disabled={this.state.loading}
                                value={this.state.email}
                                onChange={(e: any) => this.setState({email: e.target.value})} />
                        <InputError message={this.state.errors.email}/>
                    </div>
                    <div className="form-group">
                        <input type="password"
                                className={'form-control ' + (this.state.errors.password ? 'is-invalid' : '')}
                                name="password"
                                placeholder={ t('Парола:') }
                                disabled={this.state.loading}
                                value={this.state.password}
                                onChange={(e: any) => this.setState({password: e.target.value})} />
                        <InputError message={this.state.errors.password}/>
                    </div>
                    <div className="form-group">
                        <ButtonSubmit loading={this.state.loading}  label={<><i className="fa fa-fw fa-check"></i> {t('Вход')}</>}></ButtonSubmit>
                    </div>
                </>}
            />
        );
    }
};

export default withTranslation('auth')(Login);
