
import {AxiosError} from 'axios';
import i18next from 'i18next';
import {DefaultBadRequestModel} from './../models/default/default-bad-request.model';
import {DefaultValidationItemModel} from './../models/default/default-validation-item.model';

const Validator = require('max-validator');

interface badRequestError {
    name: string;
    message: string;
    code: number;
    other?: badRequestError[];
};

export class FormService {

    /**
     * Get array of bad request errors (form validations)
     * @param httpError
     * @param expectedErrors
     */
    static getServerErrors(httpError: AxiosError, expectedErrors: number[] = [400, 422, 429]): badRequestError[] {
        let errors: badRequestError[] = [];

        if (!httpError || !httpError.response || !expectedErrors.includes(httpError.response.status)) {
            return errors;
        }

        const data: DefaultBadRequestModel = httpError.response.data;
        if (!data.errors) {
            return errors;
        }

        for (let field in data.errors) {
            let item: Array<DefaultValidationItemModel> = data.errors[field];

            if (this.isString(item) || (Array.isArray(item) && this.isString(item[0]))) {
                errors.push({
                    name: field,
                    message: this.isString(item) ? String(item) : String(item[0]),
                    code: 0
                });
            } else {
                errors.push({
                    name: field,
                    message: item[0].message,
                    code: item[0].code,
                    other: item.filter((v, i) => i > 0).map(item => ({
                        name: field,
                        message: item.message,
                        code: item.code,
                    }))
                });
            }
        }

        return errors;
    }

    /**
     * Handle default bad request errors from server
     * @param httpError
     * @param setState
     * @param state
     */
    static handleServerErrors(httpError: AxiosError, setState: Function, state: any): void {
        let errors = state.errors;

        this.getServerErrors(httpError).map((item, index) => {
            if (item.name in errors) {
                errors[item.name] = item.message;
            } else {
                errors.list.push(item.message);
            }
            return item;
        });

        setState({errors, ...{
            message: i18next.t('Възникна грешка!'),
            messageType: 'danger',
        }});
    }

    /**
     * Reset errors
     * @param setState
     * @param state
     */
    static resetErrors(setState: Function, state: any): void {
        let errors = state.errors;
        for (let name in errors) {
            errors[name] = Array.isArray(errors[name]) ? [] : '';
        }

        setState({errors, ...{
            message: '',
            messageType: '',
        }});
    }

    /**
     *
     * @param setState
     * @param state
     * @param rules
     */
    static validate(setState: Function, state: any, rules: {[field: string]: any}): boolean {
        let data: any = [];

        for (let rule in rules) {
            data[rule] = state[rule];
        }


        let result = Validator.validate(data, rules);

        if (!result.hasError) {
            return true;
        }

        let errors: {[name: string]: any} = state.errors;
        for (let error in result.errors) {
            errors[error] = result.errors[error][0] || '';
        }

        setState({loading: false, errors });
        return false;
    }

    protected static isString(param: any): boolean {
        return typeof param === 'string' || param instanceof String;
    }
}
