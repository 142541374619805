import * as dotenv from "dotenv";
dotenv.config();

export const Config = {
    // Domain and API full URLs
    URL: (process.env.REACT_APP_URL || '').trim().replace(/\/+$/, "") + '/',
    API: (process.env.REACT_APP_API_URL || '').trim().replace(/\/+$/, "") + '/',

    // determine where user should go depending on his authentication status
    ROUTE_GUEST: '/auth/login',
    ROUTE_AUTH: '/',
};
