import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import {ApiAuthService} from './../../../services/api/api-auth.service';
import {FormService} from './../../../services/form.service';
import Layout from './../../../components/layouts/guest/layout-guest';
import ButtonSubmit from './../../../components/auth/button-submit/button-submit';
import {InputError} from './../../../components/input-error/input-error';

import classes from './forgot.module.scss';

class Forgot extends React.Component<WithTranslation> {
    state = {
        email: '',
        loading: false,
        message: '',
        messageType: '',
        errors: {
            list: [],
            email: ''
        }
    };

    onSubmit(e: any): void {
        const {t} = this.props;
        e.preventDefault();

        if (this.state.loading) {
            return;
        }

        FormService.resetErrors(this.setState.bind(this), this.state);
        this.setState({loading: true});

        if (!FormService.validate(this.setState.bind(this), this.state, {
            email: 'required|email|min:5',
        })) {
            return;
        }

        ApiAuthService.forgot(this.state.email).then(user => {
            this.setState({
                message: t('Изпратихме линк за смяна на паролата на Вашия имейл адрес!'),
                messageType: 'success',
            });
        }).catch(error => {
            FormService.handleServerErrors(error, this.setState.bind(this), this.state);
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    render() {
        const {t} = this.props;

        return (
            <Layout
                className={classes.layout}
                link={{to: '/auth/login', label: t("Вход в системата")}}
                message={{type: this.state.messageType, message: this.state.message}}
                onSubmit={this.onSubmit.bind(this)}
                form={<>
                    <div className="form-group">
                        <input type="text"
                                className={'form-control ' + (this.state.errors.email ? 'is-invalid' : '')}
                                name="email"
                                placeholder={t('Email:')}
                                disabled={this.state.loading}
                                value={this.state.email}
                                onChange={(e: any) => this.setState({email: e.target.value})} />
                        <InputError message={this.state.errors.email}/>
                    </div>
                    <div className="form-group">
                        <ButtonSubmit loading={this.state.loading} label={<><i className="fa fa-fw fa-send"></i> {t('Изпращане')}</>}></ButtonSubmit>
                    </div>
                </>}
            />
        );
    }
}

export default withTranslation('auth')(Forgot);
