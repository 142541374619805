import React from 'react';
import {Link} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { withTranslation, WithTranslation } from 'react-i18next';
import i18next from 'i18next';

import {LanguageChooser, Language} from '../../language-chooser/language-chooser';
import './layout-guest.scss';

interface LinkProp {
    to: string,
    label: string,
};

interface Message {
    message: string,
    type: ('primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark')|string
};

interface Props extends WithTranslation {
    form?: any,
    title?: string,
    link?: LinkProp,
    message?: Message,
    errorsList?: string[],
    className?: any,
    onSubmit?: (e: any) => void,
};

export class LayoutGuest extends React.Component<Props> {

    onSubmit(e: any): void {
        this.props.onSubmit && this.props.onSubmit(e);
    }

    onChangeLanguage(item: Language): void {
        console.log("selected language: ", item.iso);
        i18next.changeLanguage(item.iso);
    }

    render() {
        const { t } = this.props;

        return (
            <div className="background">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <LanguageChooser onSelect={this.onChangeLanguage.bind(this)} />

                            <div className="logo">
                                <a href="https://advanceacademy.bg/" target="_blank" rel="noopener noreferrer" title="Advance Academy">
                                    <img src="/assets/img/logo_white.svg" alt="Advance Academy" />
                                </a>
                            </div>

                            <h2 className="large">{this.props.title || t('Потребителски контролен панел')}</h2>

                            <form action="/" method="POST" onSubmit={this.onSubmit.bind(this)}>
                                <div className="form-wrap">
                                    {!this.props.message || !(this.props.message.message || '').length ? null : (
                                        <Alert variant={this.props.message.type}>{this.props.message.message}</Alert>
                                    )}

                                    {!this.props.errorsList || !(this.props.errorsList || []).length ? null : (
                                        <div className="list-errors alert alert-danger">
                                            <ul>
                                                {this.props.errorsList.map((item, index) => (<li key={index}>{item}</li>))}
                                            </ul>
                                        </div>
                                    )}

                                    {this.props.form}

                                    {!this.props.link ? null : (
                                        <div className="center">
                                            <Link to={this.props.link.to} className="link">{this.props.link.label || ''}</Link>
                                        </div>
                                    )}

                                </div>
                            </form>

                            <div className="questions">
                                <p>
                                    { t('При възникнали въпроси или проблеми:') }
                                    <a href="tel:+359889990030" title="+359 889990030"> +359 889990030</a>
                                </p>
                                <p>
                                    <a href="https://advanceacademy.bg/" title="Advance Academy" className="site" target="_blank" rel="noopener noreferrer">www.advanceacademy.bg</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation('auth')(LayoutGuest);
