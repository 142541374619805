import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import i18n from './services/i18n.service';

import {Config} from './services/config.service';
import {routes} from './routes';
import {auth} from './services/auth.service';
import './application.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import i18next from 'i18next';

const Validator = require('max-validator');

class application extends React.Component<WithTranslation> {
    state = {
        loading: true,
        needToRedirect: false,
        redirectTo: '',
        role: ''
    };

    setupValidators(isInit: boolean = false) {
        const {t} = this.props;

        Validator.setMessages({
            required: t('validate@Полето :name е задължително'),
            min: t('validate@Полето :name не може да бъде по-малко от :min символа'),
            max: t('validate@Полето :name не може да бъде по-голямо от :max символа'),
            between: t('validate@Полето :name трябва да е между :from и :to'),
            checked: t('validate@Полето :name трябва да бъде избрано'),
            array: t('validate@Полето :name трябва да е масив'),
            object: t('validate@Полето :name трябва да е обект'),
            boolean: t('validate@Полето :name трябва да е булев тип'),
            numeric: t('validate@Полето :name може да съдържа само числа'),
            alpha_numeric: t('validate@Полето :name може да съдържа само числа и букви'),
            alpha_dash: t('validate@Полето :name може да съдържа само букви и тирета'),
            alpha: t('validate@Полето :name може да съдържа само букви'),
            email: t('validate@Полето :name трябва да е коректен мейл'),
            in_array: t('validate@Полето :name е невалидно'),
            not_in: t("validate@Полето :name не може да бъде :value"),
            json: t('validate@Полето :name трябва да бъде валиден json'),
            ip: t('validate@Полето :name трябва да бъде валиден IP адрес'),
            url: t('validate@Полето :name трябва да бъде валиден URL адрес'),
            equals: t('validate@Полето :name трябва да бъде :value'),
            not_equals: t("validate@Полето :name не може да бъде :value"),
            contains_one: t('validate@Полето :name трябва да съдържа ":value_to_contain"'),
            contains_all: t('validate@Полето :name трябва да съдържа ":value_to_contain"'),
            starts_with: t('validate@Полето :name трябва да започва с :prefix'),
            ends_with: t('validate@Полето :name трябва да завършва с :suffix'),
            date: t('validate@Полето :name трябва да е валидна дата'),
        });

        if (isInit) {
            Validator.extend(
                'same',
                (value: string, compareTo: string) => {
                    return value === compareTo
                },
                t('validate', 'Полето :name не съвпада')
            );
        }
    }

    componentDidMount() {
        i18n.then(() => {
            this.setupValidators(true);
        });

        i18next.on("languageChanged", () => {
            this.setupValidators();
        });

        const role = auth().user.type;
        auth().onRoleChanged(() => {
            this.setState({
                role: auth().getRole(),
                needToRedirect: typeof role !== "undefined",
                redirectTo: this.redirectUser()
            });
        });

        auth().init().catch(() => {}).finally(() => {
            this.setState({role: auth().getRole(), loading: false});
        });
    }

    redirectUser(): string {
        return auth().isGuest()
            ? Config.ROUTE_GUEST
            : Config.ROUTE_AUTH
    }

    render() {
        return (
            <div className="App">
                {!this.state.loading && (
                <Router>
                    <Switch>
                        {routes.map(item => {
                            if (!item.roles || !item.roles.length || (item.roles as string[]).includes(this.state.role)) {
                                return (<Route key={item.path} path={item.path} component={item.component}></Route>)
                            } else {
                                return null;
                            }
                        })}

                        {/* Handle NO Match component */}
                        <Route component={() => <Redirect to={this.redirectUser()} />} />
                    </Switch>

                    {this.state.needToRedirect ? <Redirect to={{ pathname: this.state.redirectTo}} /> : null}
                </Router>
                )}

            </div>
        )
    }
}

export default withTranslation(['validate'])(application);
