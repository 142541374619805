import React from 'react';
import {CourseItemModel} from './../../models/course-item.model'

import classes from './course.module.scss';

interface Props {
    courses?: CourseItemModel[],
}

export class Course extends React.Component <Props> {
    state = {
        list:[
            {
                img: 'https://online.advanceacademy.bg/uploads/courses/scratch.png?version=adba1105636655ea',
                name: 'Front End Fundamentals',
                group: 'Февруари 2020',
                dates:{
                    start: '18 Фев 2020',
                    end: '05 Май 2020',
                }
            },
        ],
    }

    componentDidMount() {
        if ((this.props.courses || []).length) {
            this.setState({list: this.props.courses})
        }

    }

    render() {
        return (
            <div className="row">
                <div className="col-md-6 col-xs-12">
                    {this.state.list.map((item, index) => (
                        <div className={classes.info} key={index}>
                            <div className={classes.link} title={item.name + " " + item.group}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className={classes.imageWrapper}>
                                            <img className={classes.image} src={item.img} alt={item.name + " " + item.group} />
                                        </div>
                                        <div className={classes.itemInfo}>
                                            <span>към групата</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className={classes.details}>
                                            <h2 className={classes.course_name}>
                                                {item.name}
                                            </h2>
                                            <h3 className={classes.group_name}>
                                                {item.group}
                                            </h3>
                                            <span className={classes.second}>
                                                {item.dates.start} - {item.dates.end}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
