import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './application';
import * as serviceWorker from './serviceWorker';

import './services/i18n.service';

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={
            <div className="container-fluid d-flex" style={{height: '100vh'}}>
                <div className="row justify-content-center align-self-center text-center w-100">
                    <div className="col">
                        <i className="fa-4x fa fa-spinner fa-pulse"></i>
                    </div>
                </div>
            </div>
        }>
            <App/>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
