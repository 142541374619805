import React from 'react';
import {Link} from 'react-router-dom';
import i18next from 'i18next';

import {auth} from './../../../services/auth.service';
import {LanguageChooser, Language} from '../../language-chooser/language-chooser';
import classes from './sidebar.module.scss';

interface MenuItem {
    to: string|null,
    id?: string;
    title?: string,
    icon?: string,
};

interface Props {
    onMenuSelected?: (item: MenuItem) => void
};

export class LayoutSidebar extends React.Component<Props> {
    static defaultProps: Props = {
        onMenuSelected: (item: MenuItem) => {}
    }

    state: {
        menu: MenuItem[],
        role: string,
    } = {
        menu: [
            {to: '/', title: 'Начало', icon: 'home'},
            {to: '/profile', title: 'Настройки', icon: 'cog'},

            // route for specific role user type
            ...(auth().isStudent() ? [
                {to: '/student/messages', title: 'Съобщения', icon: 'envelope'},
                {to: '/student/homeworks', title: 'Домашни', icon: 'files-o'},
                {to: '/student/payments', title: 'Плащания', icon: 'credit-card'},
                {to: '/student/certificates', title: 'Сертификати', icon: 'address-book-o'},
                {to: '/student/calendar', title: 'Календар', icon: 'calendar'},
                {to: '/student/awards', title: 'Плащания', icon: 'trophy'}
            ] : []),

            ...(auth().isTeacher() ? [
                {to: '/teacher/groups', title: 'Групи', icon: 'file-text'},
                {to: '/teacher/lessons', title: 'Уроци', icon: 'video-camera'},
                {to: '/teacher/students', title: 'Курсисти', icon: 'users'},
                {to: '/teacher/homeworks', title: 'Домашни', icon: 'files-o'},
                {to: '/teacher/points', title: 'Точки', icon: 'gift'},
                {to: '/teacher/tests', title: 'Тестове', icon: 'thumb-tack'}
            ] : []),

            ...(auth().isAdmin() || auth().isSuperAdmin() ? [
                {to: '/admin/franchise/edit', title: 'Настройки франчайз', icon: 'pencil'},
                {to: '/admin/courses', title: 'Курсове', icon: 'book'},
                {to: '/admin/students', title: 'Курсисти', icon: 'users'},
                {to: '/admin/teachers', title: 'Преподаватели', icon: 'star'},
                {to: '/admin/materials', title: 'Материали', icon: 'files-o'},
                {to: '/admin/homeworks', title: 'Домашни', icon: 'file-archive-o'},
                {to: '/admin/payments', title: 'Плащания', icon: 'credit-card'},
                {to: '/admin/lessons', title: 'Уроци', icon: 'video-camera'},
                {to: '/admin/certificates', title: 'Сертификати', icon: 'address-book-o'},
                {to: '/admin/messages', title: 'Съобщения', icon: 'envelope'},
                {to: '/admin/tests', title: 'Тестове', icon: 'thumb-tack'},
                {to: '/admin/awards', title: 'Награди', icon: 'gift'}
            ] : []),

            {to: null, id: "instructions", title: 'Инструкции', icon: 'tasks'},
        ],
        role: auth().getRole()
    }

    componentDidMount() {

    }

    onChangeLanguage(item: Language): void {
        console.log("selected language: ", item.iso);
        i18next.changeLanguage(item.iso);
    }

    onItemClicked(item: MenuItem): void {
        if (this.props.onMenuSelected) {
            this.props.onMenuSelected(item);
        }
    }

    render() {
        return (
            <div>
                <div className="text-center">
                    <Link to='/' title="Начало" className={classes.logo_link}>
                        <img className={classes.logo} src="/assets/img/logo.svg" alt="Advance Academy" />
                    </Link>
                </div>

                <div className={classes.separator}></div>

                {!this.state.menu || !this.state.menu.length ? null : (
                    <div className={classes.pages}>
                        <ul>
                            {this.state.menu.map((item, index) => (
                                <li key={index}>
                                    {item.to === null
                                        ? <div className={classes.link} title={item.title} onClick={() => this.onItemClicked(item)}>
                                            <i className={'fa fa-fw fa-' + item.icon}></i>
                                            {item.title}
                                        </div>
                                        : <Link to={item.to} title={item.title}>
                                            <i className={'fa fa-fw fa-' + item.icon}></i>
                                            {item.title}
                                        </Link>
                                    }

                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className={classes.contacts}>
                    <LanguageChooser className="text-left"
                                     onSelect={this.onChangeLanguage.bind(this)}
                                     selected={{iso: i18next.language}}
                                     showNames={true}
                                     showInactiveOnly={true} />
                    <h3>При въпроси:</h3>
                    <a href="tel:+359889990030">+359 889 990 030</a>
                </div>
            </div>
        )
    }
}
