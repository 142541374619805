import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import './button-submit.scss';

interface Props extends WithTranslation {
    loading?: boolean,
    loadingLabel?: any,
    label?: any,
};

export class ButtonSubmit extends React.Component<Props> {
    render() {
        const {t} = this.props;
        return (
            <button type="submit" className="btn btn-primary" name="login" disabled={this.props.loading}>
            {this.props.loading
                ? (<><i className="fa fa-fw fa-cog fa-spin"></i> {this.props.loadingLabel || t('Зареждане')} </>)
                : <>{this.props.label || t('Изпращане')}</>
            }
        </button>
        );
    }
}

export default withTranslation('auth')(ButtonSubmit);
