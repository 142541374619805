import React from 'react';
import './language-chooser.scss';

export interface Language {
    iso: string,
    name?: string,
    title?: string,
    imageUrl?: string,
};

interface Props {
    className?: any;
    useDefault?: boolean;
    languages?: Language[];
    selected?: Language;
    showNames?: boolean;
    showInactiveOnly?: boolean;
    onSelect?: (language: Language) => void;
};

export class LanguageChooser extends React.Component<Props> {
    static defaultProps: Props = {
        className: 'text-right',
        useDefault: true,
    };
    state: {
        languages: Language[],
        selected: Language,
    } = {
        languages: [],
        selected: {} as Language,
    };

    readonly DEFAULT_LANGUAGES: Language[] = [{
        iso: 'bg',
        name: 'Български',
        title: 'Превключване на Български',
        imageUrl: '/assets/img/flags/bg.jpg',
    }, {
        iso: 'en',
        name: 'English',
        title: 'Switch to English',
        imageUrl: '/assets/img/flags/en.jpg',
    }];

    componentDidMount() {
        const languages: Language[] = this.props.languages && this.props.languages.length
            ? this.props.languages.slice()
            : this.props.useDefault
                ? this.DEFAULT_LANGUAGES.slice()
                : [];

        this.setState({languages, selected: this.props.selected || languages[0]});
    }

    onSelectLanguage(e: any, item: Language): void {
        e.preventDefault();
        this.props.onSelect && this.props.onSelect(item);
        this.setState({selected: item});
    }

    render() {
        return (
            <div className={this.props.className + ' language-chooser'}>
                {!this.state.languages || !this.state.languages.length ? null :
                    this.state.languages
                        .filter(item => !this.props.showInactiveOnly || item.iso !== this.state.selected.iso)
                        .map((item: Language) => (
                            <a key={item.iso}
                                href="/#"
                                onClick={(e) => this.onSelectLanguage(e, item)}
                                className="flag"
                                title={item.title}
                            >
                                <img src={item.imageUrl} alt={item.title} className={this.props.showNames ? 'smaller' : ''}/>
                                {this.props.showNames && <span>{item.name}</span>}
                            </a>
                        ))
                }
            </div>
        );
    }
}
