import React, { ChangeEvent, RefObject } from 'react';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {WithTranslation, withTranslation} from 'react-i18next';

import {UploadFileModel} from '../../models/upload-file.model';
import {FormService} from './../../services/form.service';
import {ApiUploadsService, ApiUploadsServiceTypes} from './../../services/api/api-uploads.service';
import classes from './upload.module.scss';

export interface UploadFileQueue {
    index: number;
    file: File;
    chunks: number;
    chunk: number;
    error: any;
    response: any|UploadFileModel;
};

interface Props extends WithTranslation {
    // Which kind of upload should be performed
    type: ApiUploadsServiceTypes;

    // chunk size
    size?: number;

    // how big file can be handled at once
    maxSize?: number;

    // how many files per single query
    files?: number;

    // Button label
    label?: string;

    // Button label while uploading
    labelUploading?: string;

    // Set disable state
    disabled?: boolean;

    // call function when a file is finished
    onFileUploaded?: (file: UploadFileQueue, response: any|UploadFileModel) => void;

    // call function when progress of file is changed
    onFileProgress?: (file: UploadFileQueue, progress: number) => void;

    // call function if there is an error (HTTP or other)
    onFileError?: (file: UploadFileQueue, error: any) => void;

    // when all files are either finished or had errors
    onFilesFinished?: (files: UploadFileQueue[]) => void;
};

export class Upload extends React.Component<Props> {
    static readonly LABEL: string = 'Избор на файл';
    static readonly LABEL_UPLOADING: string = 'Качване...';

    static defaultProps = {
        size: 2048, // kb
        maxSize: 10 * 1024, // kb
        files: 1,
        label: Upload.LABEL,
        labelUploading: Upload.LABEL_UPLOADING,
        disabled: false,

        onFileUploaded: (file: UploadFileQueue, response: any|UploadFileModel) => { },
        onFileProgress: (file: UploadFileQueue, progress: number) => { },
        onFileError: (file: UploadFileQueue, e: any) => { },
        onFilesFinished: (files: UploadFileQueue[]) => { },
    };

    state = {
        id: '_' + Math.random().toString(36).substr(2, 9),
        label: this.props.label,
        labelUploading: this.props.labelUploading,
        isButtonHover: false,
        uploading: false,
        progress: 0,
        message: '',
        messageType: '',
        errors: {
            list: [],
            file: '',
        }
    };
    protected button: RefObject<HTMLButtonElement>;
    protected filesQueue: UploadFileQueue[] = [];

    constructor(props: Props) {
        super(props)
        this.button = React.createRef()
    }

    onFileSelected(event:  ChangeEvent<HTMLInputElement>): void {
        if (this.state.uploading) {
            return;
        }
        this.filesQueue = Array.prototype.slice.call(event.target.files, 0, this.props.files).map((item: File, index: number) => ({
            index,
            file: item,
            chunk: 0,
            chunks: this.getChunksCount(item),
            error: null,
            response: null,
        }));
        this.proceedUpload();
    }

    protected async proceedUpload() {
        FormService.resetErrors(this.setState.bind(this), this.state);
        this.setState({uploading: true, progress: 0});
        this.setProgressButton(true);

        const method = this.props.type === 'image' ? ApiUploadsService.image
            : this.props.type === 'file' ? ApiUploadsService.file
            : this.props.type === 'homework' ? ApiUploadsService.homework
            : this.props.type === 'avatar' ? ApiUploadsService.avatar
            : this.props.type === 'certificate' ? ApiUploadsService.certificate
            : async (...args: any[]) => {};

        for (let index in this.filesQueue) {
            let item = this.filesQueue[index];
            let chunks = item.chunks && item.chunks > 1 ? item.chunks : undefined;

            for (let chunk = 0, uploaded = 0; chunk < item.chunks; chunk++) {
                try {
                    if (item.file.size > (this.props.maxSize || Upload.defaultProps.maxSize) * 1024) {
                        throw new Error("File Too Big");
                    }

                    let response = await method(item.file.name, this.getChunks(item.file, chunk), chunks, chunk, (event: any) => {
                        uploaded += event.loaded || 0;
                        let progress = Math.floor((uploaded * 100) / item.file.size);
                        progress = progress <= 100 ? progress : 100;

                        let totalProgress = Math.round(((100 * Number(index)) + progress) / this.filesQueue.length);
                        totalProgress = totalProgress <= 100 ? totalProgress : 100;

                        this.setState({label: this.state.labelUploading, progress: totalProgress});
                        this.props.onFileProgress && this.props.onFileProgress(item, progress);
                    });

                    if ((item.chunks - 1) <= chunk) {
                        item.response = response;
                        this.props.onFileUploaded && this.props.onFileUploaded(item, response);
                    }
                } catch (error) {
                    this.props.onFileError && this.props.onFileError(item, error);
                    FormService.handleServerErrors(error, this.setState.bind(this), this.state);
                    item.error = error;
                    break;
                }
            }
        }

        this.setState({
            uploading: false,
            label: this.props.label || Upload.defaultProps.label || '',
            progress: 0,
        });

        this.setProgressButton(false);

        this.props.onFilesFinished && this.props.onFilesFinished(this.filesQueue);
    }

    protected getChunksCount(file: File) {
        const size = Math.abs(this.props.size || 2048) * 1024;
        return Math.ceil(file.size / size);
    }

    protected getChunks(file: File, chunk: number = 0): Blob {
        const size = Math.abs(this.props.size || 2048) * 1024;
        let chunksCount = this.getChunksCount(file);
        chunk = chunk < 0 ? 0 : chunk >= chunksCount ? chunksCount - 1 : chunk;

        return file.slice(
            chunk * size, Math.min(chunk * size + size, file.size), file.type
        );
    }

    protected setProgressButton(toggle: boolean = true) {
        if (toggle && this.button.current) {
            this.button.current.style.minWidth = ~~this.button.current.getBoundingClientRect().width + 'px';
        } else if (this.button.current) {
            this.button.current.style.minWidth = 'auto';
        }
    }

    render() {
        return (
            <div className={classes.wrap}
                onMouseEnter={() => {this.setState({isButtonHover: true})}}
                onMouseLeave={() => {this.setState({isButtonHover: false})}}
                title={this.state.errors.file}
            >
                {!this.state.progress ? null :
                    <ProgressBar className={classes.progress} animated now={this.state.progress} variant="warning" />}

                <Button className={classes.button}
                    active={this.state.isButtonHover}
                    variant={this.state.errors.file ? 'danger' : 'primary'}
                    disabled={this.state.uploading || this.props.disabled}
                    ref={this.button}
                >
                    {this.state.errors.file && <><i className="fa fa-fw text-warning fa-exclamation-triangle"></i>&nbsp;</>}
                    {this.state.uploading && <><i className="fa fa-spinner fa-pulse fa-fw"></i>&nbsp;</>}
                    {this.state.label}
                </Button>

                <label className={classes.uploadLabel}
                    htmlFor={'fileUpload' + this.state.id}
                ></label>

                <input tabIndex={0}
                    disabled={this.state.uploading || this.props.disabled}
                    className={classes.upload}
                    id={'fileUpload' + this.state.id}
                    type="file"
                    onChange={this.onFileSelected.bind(this)}
                    multiple={(this.props.files || 0) > 1}
                />

            </div>
        )
    }
}

export default withTranslation()(Upload);
