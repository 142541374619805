import React from 'react';

import {RouteModel as Route} from './models/route.model';
import {getFlatRoutes} from './helpers/routes-helper';
import Login from './pages/auth/login/login';
import Forgot from './pages/auth/forgot/forgot';
import Reset from './pages/auth/reset/reset';
import Home from './pages/home/home';
import Profile from './pages/profile/profile';

export const routes: Route[] = getFlatRoutes([{
    path: '/auth',
    roles: ['guest'],
    children: [{
        path: '/login',
        component: () => <Login/>,
    }, {
        path: '/forgot',
        component: () => <Forgot/>,
    }, {
        path: '/reset',
        component: () => <Reset/>,
    }]
}, {
    path: '/profile',
    roles: ['student', 'teacher', 'super_admin', 'admin'],
    component: () => <Profile/>,
}, {
    path: '/',
    roles: ['student', 'teacher', 'super_admin', 'admin'],
    component: () => <Home/>,
}]);
