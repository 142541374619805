import React from 'react';

interface Props {
    message?: string
};

export class InputError extends React.Component<Props> {
    render() {
        return (
            <>{this.props.message && <div className="text-danger small text-left">{this.props.message}</div>}</>
        )
    }
}

