import {Observer} from './../contracts/observer';
import {UserModel} from './../models/user/user.model';
import {ApiAuthService} from './api/api-auth.service';
import {ApiProfileService} from './api/api-profile.service';

type AuthServiceEvents = ('changeRole');


export class AuthService extends Observer<AuthServiceEvents>() {
    protected readonly NAME_TOKEN: string = 'user';

    private static instance: AuthService;
    protected innerToken: string = '';
    protected innerUser: UserModel = {} as UserModel;

    get token(): string {
        return this.innerToken;
    }
    set token(token: string) {
        if (this.innerToken !== token) {
            this.innerToken = token;
            this.save().finally();
        }
    }

    get user(): UserModel {
        return this.innerUser;
    }
    set user(user: UserModel) {
        if (this.innerUser !== user) {
            this.innerUser = user;
        }
    }

    private constructor() {
        super();
    }

    static getInstance(): AuthService {
        if (!AuthService.instance) {
            AuthService.instance = new AuthService();
        }
        return AuthService.instance;
    }

    /**
     * Init authentication
     */
    public async init(): Promise<any> {
        ApiAuthService.on('unauthorized', (error: any) => {
            this.token = '';
        });
        return await this.load();
    }

    /**
     * Check if there is signed-in user
     */
    public isAuth(): boolean {
        return this.user && this.user.id > 0;
    }

    /**
     * Sign-out an user
     */
    public logout(): Promise<any> {
        return this.setToken('');
    }

    /**
     * Subscribe to event 'changeRole'
     * @param callback
     */
    public onRoleChanged(callback: CallableFunction) {
        AuthService.on('changeRole', callback);
    }

    public setToken(token: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.innerToken = token;
            this.save()
                .then(data => resolve(data))
                .catch(e => reject(e));
        });
    }

    public getRole(): string {
        if (!this.isAuth()) {
            return 'guest';
        } else {
            return (this.user.type.title || '').toLowerCase();
        }
    }

    public isGuest() {
        return this.getRole() === 'guest';
    }

    public isSuperAdmin(): boolean {
        return this.getRole() === 'super_admin';
    }

    public isAdmin(): boolean {
        return this.getRole() === 'admin';
    }

    public isTeacher(): boolean {
        return this.getRole() === 'teacher';
    }

    public isStudent(): boolean {
        return this.getRole() === 'student';
    }

    public forceUpdateProfile(user?: UserModel) {
        if (user) {
            this.user = user;
            AuthService.fire('changeRole');
        } else {
            this.load();
        }
    }

    protected load(): Promise<UserModel> {
        return new Promise((resolve, reject) => {
            try {
                const role = this.getRole();
                this.innerToken = localStorage.getItem(this.NAME_TOKEN) || '';

                if (this.innerToken && this.innerToken.length) {
                    this.fetchProfile()
                        .then(user => {
                            this.innerUser = user;
                            if (role !== this.getRole()) {
                                AuthService.fire('changeRole');
                            }
                            resolve(user)
                        })
                        .catch(error => reject(error));
                } else {
                    reject();
                }
            } catch (e) {
                reject(e);
            }
        });
    }

    protected save(): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const role = this.getRole();
                localStorage.setItem(this.NAME_TOKEN, this.innerToken);

                if (!this.innerToken || !this.innerToken.length) {
                    ApiAuthService.logout().catch(() => {}).finally(() => {
                        this.user = {} as UserModel;

                        ApiAuthService.AUTHORIZATION = '';

                        if (role !== this.getRole()) {
                            AuthService.fire('changeRole');
                        }

                        resolve();
                    });
                } else {
                    this.fetchProfile().then(user => {
                        this.innerUser = user;

                        if (role !== this.getRole()) {
                            AuthService.fire('changeRole');
                        }

                        resolve()
                    });
                }
            } catch (e) {
                reject();
            }
        });
    }

    protected fetchProfile(): Promise<UserModel> {
        ApiProfileService.AUTHORIZATION = this.innerToken;

        return new Promise((resolve, reject) => {
            ApiProfileService.profile().then(data => {
                resolve(data.data);
            }).catch(error => {
                reject(error);
            });
        });
    }
}

export const auth: () => AuthService = () => AuthService.getInstance();
